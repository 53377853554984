<template>
  <b-modal
      id="modal-edit-working-hours"
      size="lg"
      ref="modal"
      centered
      @hidden="resetModal"
  >
    <template #modal-header="{ close }">
      <div class="w-100">
        <h5 class="float-left font-weight-bolder text-dark">{{ $t("ANALYTICS.WORKING_HOURS_TITLE") }}</h5>
        <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
      </div>
    </template>
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <div v-for="(day, index) in weekData" :key="index" class="form-group row">
        <label class="col-3 col-form-label">{{ day.title.name }}:</label>
        <div class="col-2 pt-1">
          <span class="switch">
            <label>
              <input type="checkbox" @click="day.isChecked = !day.isChecked" :checked="day.isChecked" name="select"/>
              <span></span>
            </label>
          </span>
        </div>
        <div class="input-group col-3">
          <date-picker class="form-control" name="timePicker" :disabled="!day.isChecked" v-model="day.time_start" :config="options2"></date-picker>
          <div class="input-group-append">
           <span class="input-group-text">
            <span><i class="fas fa-clock"></i></span>
           </span>
          </div>
        </div>
        <div class="col-1">
          <div class="ml-3 pt-3 font-size-lg font-weight-bolder text-primary">&mdash;</div>
        </div>
        <div class="input-group col-3">
          <date-picker class="form-control" name="timePicker" :disabled="!day.isChecked" v-model="day.time_end" :config="options2"></date-picker>
          <div class="input-group-append">
           <span class="input-group-text">
            <span><i class="fas fa-clock"></i></span>
           </span>
          </div>
        </div>
      </div>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="light-primary"
            class="float-left font-weight-bolder"
            @click="resetModal">
          {{ $t("ANALYTICS.CANCEL_BUTTON") }}
        </b-button>
        <p class="float-right">
          <b-button
              variant="secondary"
              class="float-left font-weight-bolder mr-2"
              @click="defaultModal">
            {{ $t("ANALYTICS.RESET_BUTTON") }}
          </b-button>
          <b-button
              class="font-weight-bolder"
              variant="primary"
              @click="handleOk">
            {{ $t("ANALYTICS.APPLY_BUTTON") }}
          </b-button>
        </p>
      </div>
    </template>
  </b-modal>
</template>

<script>
import moment from 'moment';
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "AddEditWorkingHours",
  data() {
    return {
      options2: {
        locale: i18nService.getActiveLanguage(),
        format: 'HH:mm',
        useCurrent: true,
        showClear: true,
        showClose: true
      },
      weekData: [
        {
          title: {
            id: 'monday',
            name: this.$t("WEEK_DAYS.MONDAY")
          },
          isChecked: true,
          time_start: '08:00',
          time_end: '20:00',
        },
        {
          title: {
            id: 'tuesday',
            name: this.$t("WEEK_DAYS.TUESDAY")
          },
          isChecked: true,
          time_start: '08:00',
          time_end: '20:00',
        },
        {
          title: {
            id: 'wednesday',
            name: this.$t("WEEK_DAYS.WEDNESDAY")
          },
          isChecked: true,
          time_start: '08:00',
          time_end: '20:00',
        },
        {
          title: {
            id: 'thursday',
            name: this.$t("WEEK_DAYS.THURSDAY")
          },
          isChecked: true,
          time_start: '08:00',
          time_end: '20:00',
        },
        {
          title: {
            id: 'friday',
            name: this.$t("WEEK_DAYS.FRIDAY")
          },
          isChecked: true,
          time_start: '08:00',
          time_end: '20:00',
        },
        {
          title: {
            id: 'saturday',
            name: this.$t("WEEK_DAYS.SATURDAY")
          },
          isChecked: false,
          time_start: '08:00',
          time_end: '20:00',
        },
        {
          title: {
            id: 'sunday',
            name: this.$t("WEEK_DAYS.SUNDAY")
          },
          isChecked: false,
          time_start: '08:00',
          time_end: '20:00',
        }
      ]
    }
  },
  methods: {
    resetModal() {
      this.$bvModal.hide('modal-edit-working-hours');
    },
    defaultModal() {
      this.weekData = [
        {
          title: {
            id: 'monday',
            name: this.$t("WEEK_DAYS.MONDAY")
          },
          isChecked: true,
          time_start: '08:00',
          time_end: '20:00',
        },
        {
          title: {
            id: 'tuesday',
            name: this.$t("WEEK_DAYS.TUESDAY")
          },
          isChecked: true,
          time_start: '08:00',
          time_end: '20:00',
        },
        {
          title: {
            id: 'wednesday',
            name: this.$t("WEEK_DAYS.WEDNESDAY")
          },
          isChecked: true,
          time_start: '08:00',
          time_end: '20:00',
        },
        {
          title: {
            id: 'thursday',
            name: this.$t("WEEK_DAYS.THURSDAY")
          },
          isChecked: true,
          time_start: '08:00',
          time_end: '20:00',
        },
        {
          title: {
            id: 'friday',
            name: this.$t("WEEK_DAYS.FRIDAY")
          },
          isChecked: true,
          time_start: '08:00',
          time_end: '20:00',
        },
        {
          title: {
            id: 'saturday',
            name: this.$t("WEEK_DAYS.SATURDAY")
          },
          isChecked: false,
          time_start: '08:00',
          time_end: '20:00',
        },
        {
          title: {
            id: 'sunday',
            name: this.$t("WEEK_DAYS.SUNDAY")
          },
          isChecked: false,
          time_start: '08:00',
          time_end: '20:00',
        }
      ]
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      let dataForSend = [];
      this.weekData.forEach(item => {
        let startHour = moment(item.time_start, 'HH::mm');
        let endHour = moment(item.time_end, 'HH::mm');
        let milliseconds = endHour - startHour;
        let hours = moment.utc(milliseconds).format('H');

        dataForSend.push({
          day: item.title.id,
          value: item.isChecked ? parseInt(hours) : 0
        });
      })

      this.$emit('changeWorkingHours', dataForSend);
      this.$bvModal.hide('modal-edit-working-hours');
    }
  }
}
</script>

<style scoped>

</style>
