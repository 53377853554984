<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <circle fill="#EDB149" opacity="0.3" cx="12" cy="12" r="10"/>
        <rect fill="#FFA601" x="11" y="7" width="2" height="8" rx="1"/>
        <rect fill="#FFA601" x="11" y="16" width="2" height="2" rx="1"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Warning"
}
</script>

<style scoped>

</style>