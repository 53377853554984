<template>
  <b-modal
      id="modal-edit-period-date"
      ref="modal"
      centered
      @hidden="resetModal"
  >
    <template #modal-header="{ close }">
      <div class="w-100">
        <h5 class="float-left font-weight-bolder text-dark">{{ $t("ANALYTICS.MANUAL_PERIOD_FILTER_SELECT") }}</h5>
        <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
      </div>
    </template>
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <div class="row align-content-center mb-2">
        <div class="col-lg-12 col-xl-12">
          <div class="row align-items-center">
            <div class="col-md-3 my-2 my-md-0">
              <label class="mr-3 d-none d-md-block">{{ $t("ANALYTICS.TIME_FROM") }}</label>
              <date-picker v-model="from" :config="optionsDatePicker"></date-picker>
            </div>
            <div class="col-1">
              <div class="pt-md-7 pt-lg-7 pt-xl-7 pt-0 font-size-lg font-weight-bolder text-primary">&mdash;</div>
            </div>
            <div class="col-md-3 my-2 my-md-0">
              <label class="mr-3 d-none d-md-block">{{ $t("ANALYTICS.TIME_TO") }}</label>
              <date-picker v-model="to" :config="optionsDatePicker"></date-picker>
            </div>
          </div>
        </div>
      </div>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="light-primary"
            class="float-left font-weight-bolder"
            @click="resetModal">
          {{ $t("ANALYTICS.CANCEL_BUTTON") }}
        </b-button>
        <p class="float-right">
          <b-button
              class="font-weight-bolder"
              variant="primary"
              @click="handleOk">
            {{ $t("ANALYTICS.APPLY_BUTTON") }}
          </b-button>
        </p>
      </div>
    </template>
  </b-modal>
</template>

<script>
import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "EditPeriodDateModal",
  data() {
    return {
      from: moment(new Date()).startOf('month').format('DD.MM.YYYY'),
      to: moment(new Date()).add(-1, 'days').format('DD.MM.YYYY'),
      optionsDatePicker: {
        locale: i18nService.getActiveLanguage(),
        format: 'DD.MM.YYYY',
        useCurrent: true,
        showClear: false,
        showClose: false,
        maxDate: moment(new Date()).add(-1, 'days')
      },
    }
  },
  methods: {
    resetModal() {
      this.$emit('changePeriodDate', { from: this.from, to: this.to });
      this.$bvModal.hide('modal-edit-period-date');
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.$emit('changePeriodDate', { from: this.from, to: this.to });
      this.$bvModal.hide('modal-edit-period-date');
    }
  }
}
</script>

<style scoped>

</style>
